import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Api, { IAddEmploymentDetailsRequest, IAnswerSet, ICallApiArgs, IEmploymentDetail, IEnumValue, IExamFlow, IRespondent, IRespondentPhotoResponse, TrainingNeeds, useApi, WebUserType } from '../api/Api';
import NavigateHelper, { TakePhotoType } from '../api/NavigateHelper';
import QuestionContext from '../api/QuestionContext';
import { UiHelper } from '../api/UiHelper';
import FormGroup from './0-atoms/FormGroup';
import TextAreaLabel from './0-atoms/TextAreaLabel';
import YesNoLabel from './0-atoms/YesNoLabel';
import YesNoWidget from './0-atoms/YesNoWidget';
import SignoffLabel from './1-molecules/SignoffLabel';
import SignoffWidget from './1-molecules/SignoffWidget';
import AnswerSetWidget from './AnswerSetWidget';
import VcrButtons from './VcrButtons';

const EfProfile: React.FC<{
    answerSet: IAnswerSet,
    respondent: IRespondent,
    examFlow: IExamFlow,
    finish: () => void,
    context: QuestionContext
}> = ({
    answerSet,
    respondent,
    examFlow,
    finish,
    context
}) => {

    enum ProfileTab {
        Details = 0,
        ProfessionalDiscussion = 1
    }

    const [api, setApi] = useState<Api>();
    const [_respondent, _setRespondent] = useState<IRespondent>();
    const [dateOfBirthAsString, setDateOfBirthAsString] = useState<string>("");
    const [healthAndSafetyPassedDateAsString, setHealthAndSafetyPassedDateAsString] = useState<string>("");
    const [trainingNeeds, setTrainingNeeds] = useState<IEnumValue[]>();
    const [employmentDetails, setEmploymentDetails] = useState<IEmploymentDetail[]>([]);
    const [areTrainingDatesVisible, setAreTrainingDatesVisible] = useState<boolean>(false);
    const [tab, setTab] = useState<ProfileTab>(ProfileTab.Details);
    const [photo, setPhoto] = useState<IRespondentPhotoResponse>();

    const getNullAddEmploymentDetailsRequest = (): IAddEmploymentDetailsRequest => {
        return {
            name: "",
            dates: "",
            details: ""
        }
    }

    const [addEmploymentDetailsArgs, setAddEmploymentDetailsArgs] = useState<IAddEmploymentDetailsRequest>(getNullAddEmploymentDetailsRequest());

    const daysTraining = new Array<number>();
    for (let index = 0; index < 10; index++)
        daysTraining.push(index + 1);

    const navigate = useNavigate();

    useApi(async (api) => {
        setApi(api);
        setPhoto(await api.getRespondentPhotoAsync());
        setTrainingNeeds(await api.getTrainingNeedsAsync());
    });

    const fetchEmployment = useCallback(async () => {
        const employment = await api!.getEmploymentDetailsForCurrentWorkspaceAsync();
        setEmploymentDetails(employment);
    }, [api])

    useEffect(() => {
        if (_respondent == null && respondent != null && api != null) {
            _setRespondent(respondent);

            if (respondent.dateOfBirth == "0001-01-01")
                setDateOfBirthAsString("");
            else
                setDateOfBirthAsString(respondent.dateOfBirth.toString());

            if (respondent.healthAndSafetyPassedDate === "0001-01-01")
                setHealthAndSafetyPassedDateAsString("");
            else
                setHealthAndSafetyPassedDateAsString(respondent.healthAndSafetyPassedDate.toString());

            fetchEmployment();
        }
    }, [_respondent, respondent, fetchEmployment, api]);

    const setRespondentProperty = (name: string, value: any) => {
        let ok = false;
        if (name == "dateOfBirth") {
            try {
                new Date(value);
                ok = true;
            } catch {
                ok = false;
            }

            setDateOfBirthAsString(value);

        } else  if (name === "healthAndSafetyPassedDate") {
            try {
                new Date(value);
                ok = true;
            } catch {
                ok = false;
            }

            setHealthAndSafetyPassedDateAsString(value);
        } else
            ok = true;

        if (ok) {
            _setRespondent({
                ..._respondent!,
                [name]: value
            });
        }
    }

    const touchRespondent = async (ignoreErrors?: boolean) => {
        if (ignoreErrors == null)
            ignoreErrors = true;
        return await api!.updateRespondentForCurrentWorkspaceAsync(_respondent!, ignoreErrors);
    };

    const handleFinish = async () => {
        const response = await touchRespondent(false);
        if(!(response.hasErrors))
            finish();
    }

    const handleSignoff = async () => {
        if (context.userType === WebUserType.Respondent) {
            await api!.signOffRespondentAsync();
            setRespondentProperty("hasRespondentSignedOff", true);
        } else if (context.userType === WebUserType.User) {
            await api!.signOffInstructorAsync();
            setRespondentProperty("hasInstructorSignedOff", true);
        } else
            throw new Error("Cannot handle: " + context.userType);
    }

    const renderAddEmploymentDetails = () => {
        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="card bg-light">
                        <div className="card-body">
                            <div>
                                <h4>Add Employment Details</h4>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Employer name" value={addEmploymentDetailsArgs.name} onChange={(e) => handleAddEmploymentDetailsArgsChange("name", e.target.value)}></input>
                            </div>
                            <div className="form-group mt-2">
                                <input type="text" className="form-control" placeholder="Dates of employment" value={addEmploymentDetailsArgs.dates} onChange={(e) => handleAddEmploymentDetailsArgsChange("dates", e.target.value)}></input>
                                <div>
                                    Approximate dates are acceptable here. Include your current employer.
                                </div>
                            </div>
                            <div className="form-group mt-4">
                                <label>Details for work experience</label>
                                <textarea className="form-control height-150px" value={addEmploymentDetailsArgs.details} onChange={(e) => handleAddEmploymentDetailsArgsChange("details", e.target.value)}></textarea>
                            </div>
                            <div className="form-group mt-2">
                                <button className="btn btn-sm btn-success" onClick={() => addEmploymentDetails()}>Add Employment Details</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const addEmploymentDetails = async () => {
        await api!.addEmploymentDetailsToCurrentWorkspaceAsync(addEmploymentDetailsArgs);
        setAddEmploymentDetailsArgs(getNullAddEmploymentDetailsRequest());
        fetchEmployment();
    }

    const deleteEmploymentDetails = (item: IEmploymentDetail) => {
        UiHelper.confirm(async () => {
            await api!.deleteEmploymentDetailsToCurrentWorkspaceAsync(item);
            fetchEmployment();
        });
    }

    const handleAddEmploymentDetailsArgsChange = (field: string, value: string) => {
        const newArgs = {
            ...addEmploymentDetailsArgs!,
        };
        (newArgs as any)[field] = value;
        setAddEmploymentDetailsArgs(newArgs);
    }

    const renderEmploymentDetails = () => {
        if (_respondent != null) {
            if (employmentDetails.length > 0) {
                return (
                    <div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <td className="width-33pc">Employer</td>
                                    <td className="width-33pc">Dates</td>
                                    <td className="width-33pc">Details</td>
                                    <td className="width-125px">
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {employmentDetails.map((item) => {
                                    return (
                                        <tr key={item.token}>
                                            <td>{item.name}</td>
                                            <td>{item.dates}</td>
                                            <td>{item.details}</td>
                                            <td>
                                                <div className="text-end">
                                                    <button className="btn btn-sm btn-danger" onClick={() => deleteEmploymentDetails(item)}><i className="fa fa-trash"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                         <div>
                            {renderAddEmploymentDetails()}
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <div>
                            Add employment details to get started
                        </div>
                        <div>
                            {renderAddEmploymentDetails()}
                        </div>
                    </div>
                )
            }
        } else
            return (<></>)
    }

    const renderRespondent = () => {
        if (_respondent != null && examFlow != null && trainingNeeds != null) {
            return (
                <div>
                    <div className="mb-5">
                        <h2>Overview</h2>
                        <FormGroup>
                            <label>First name</label>
                            {context.userType === WebUserType.Respondent &&
                                <input type="text" className="form-control" value={_respondent.firstName} onChange={(e) => setRespondentProperty("firstName", e.target.value)}
                                    onBlur={() => touchRespondent()} />
                            }
                            {context.userType === WebUserType.User &&
                                <div>{_respondent.firstName}</div>
                            }
                        </FormGroup>
                        <FormGroup>
                            <label>Last name</label>
                            {context.userType === WebUserType.Respondent &&
                                <input type="text" className="form-control" value={_respondent.lastName} onChange={(e) => setRespondentProperty("lastName", e.target.value)} 
                                    onBlur={() => touchRespondent()} />
                            }
                            {context.userType === WebUserType.User &&
                                <div>{_respondent.lastName}</div>
                            }
                        </FormGroup>
                        <FormGroup>
                            <label>Date of birth</label>
                            {context.userType === WebUserType.Respondent &&
                                <input type="text" className="form-control" value={dateOfBirthAsString} onChange={(e) => setRespondentProperty("dateOfBirth", e.target.value)}
                                    onBlur={() => touchRespondent()} />
                            }
                            {context.userType === WebUserType.User && !(_respondent.hasDateOfBirth) &&
                                <div>-</div>
                            }
                            {context.userType === WebUserType.User && _respondent.hasDateOfBirth &&
                                <div>{_respondent.dateOfBirth}</div>
                            }
                        </FormGroup>
                    </div>
                    <div className="mb-5">
                        <FormGroup>
                            <label>Instructor</label>
                            <div>
                                {examFlow.instructor.fullName}
                            </div>
                        </FormGroup>
                    </div>
                    <div className="mb-5">
                        {renderPhoto()}
                    </div>
                    <div className="mb-5">
                        <FormGroup>
                            <label>Training and assessment dates</label>
                            <div>
                                {renderTrainingDates()}
                            </div>
                        </FormGroup>
                    </div>
                </div>
            )
        } else
            return (<></>)
    }

    const renderPhoto = () => {
        if (photo != null) {
            if (photo.hasData) {
                return (
                    <div>
                        <img src={photo.data}></img>
                        <button className="btn btn-sm btn-primary mt-2" onClick={() => takePhoto(true)}>Retake Photo</button>
                    </div>
                )
            } else {
                return (
                    <button className="btn btn-sm btn-primary" onClick={() => takePhoto(true)}>Take Photo</button>
                )
            }
        } else
            return (<></>)
    }

    const takePhoto = (forRespondent: boolean) => {
        if(forRespondent)
            new NavigateHelper(navigate).goTakePhoto(TakePhotoType.Respondent);
        else
            new NavigateHelper(navigate).goTakePhoto(TakePhotoType.AdHoc);
    }

    const renderTrainingDates = () => {
        if (examFlow != null) {
            if (areTrainingDatesVisible) {
                return (
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                            </tr>
                        </thead>
                        <tbody>
                            {examFlow.reservation.dates.map((item) => {
                                return (
                                    <tr key={item.dateAsString}>
                                        <td>{item.dateAsString}</td>
                                        <td>{item.startTimeAsString}</td>
                                        <td>{item.endTimeAsString}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )
            } else {
                return (
                    <a className="clickable" onClick={() => setAreTrainingDatesVisible(true)}>Show</a>
                )
            }
        } else
            return (<></>)
    }

    const renderTrainingNeeds = () => {
        if (_respondent != null) {
            return (
                <div>
                    <FormGroup>
                        <h2>
                            Training needs
                        </h2>
                        <div>
                            {context.userType === WebUserType.User &&
                                <select className="form-control" value={_respondent.trainingNeeds} onChange={(e) => setRespondentProperty("trainingNeeds", e.target.value)}
                                    onBlur={() => touchRespondent()}>
                                    {trainingNeeds!.map((item) => {
                                        return (
                                            <option key={item.value} value={item.value}>{item.name}</option>
                                        )
                                    })}
                                </select>
                            }
                        </div>
                    </FormGroup>

                    {_respondent.trainingNeeds === TrainingNeeds.FullTrainingRequired &&
                        <FormGroup>
                            <label>The operator requires full duration basic training for the following reasons:</label>
                            {context.userType === WebUserType.User &&
                                <textarea className="form-control" value={_respondent.fullDurationBasicTrainingReasons} onChange={(e) => setRespondentProperty("fullDurationBasicTrainingReasons", e.target.value)}
                                    onBlur={() => touchRespondent()}></textarea>
                            }
                            {context.userType === WebUserType.Respondent &&
                                <TextAreaLabel value={_respondent.fullDurationBasicTrainingReasons}></TextAreaLabel>
                            }
                        </FormGroup>
                    }

                    {_respondent.trainingNeeds === TrainingNeeds.ReducedTrainingSufficient &&
                        <FormGroup>
                            <label>The stated novice course duration was reduced on this occassion for the following reasons:</label>
                            {context.userType === WebUserType.User &&
                                <textarea className="form-control" value={_respondent.courseDurationReducedReasons} onChange={(e) => setRespondentProperty("courseDurationReducedReasons", e.target.value)}
                                    onBlur={() => touchRespondent()}></textarea>
                            }
                            {context.userType === WebUserType.Respondent &&
                                <TextAreaLabel value={_respondent.courseDurationReducedReasons}></TextAreaLabel>
                            }
                        </FormGroup>
                    }

                    {(_respondent.trainingNeeds == TrainingNeeds.FullTrainingRequired || _respondent.trainingNeeds == TrainingNeeds.ReducedTrainingSufficient) &&  
                        <FormGroup>
                            <label>The operator requires the following days training:</label>
                            {context.userType === WebUserType.User &&
                                <select className="form-control" value={_respondent.trainingRequirementDays} onChange={(e) => setRespondentProperty("trainingRequirementDays", e.target.value)}
                                    onBlur={() => touchRespondent()}>
                                    <option value="0">(Select)</option>
                                    {daysTraining.map((day) => {
                                        return (
                                            <option key={day} value={day}>{day} day{day !== 1 && <span>s</span>}</option>
                                        )
                                    })}
                                </select>
                            }
                            {(context.userType === WebUserType.Respondent && _respondent.trainingRequirementDays > 0) &&
                                <div>
                                    {_respondent.trainingRequirementDays} day{_respondent.trainingRequirementDays !== 1 && <span>s</span>}
                                </div>}
                        </FormGroup>
                    }
                </div>
            )
        } else
            return (<></>)
    }

    const renderSignoff = () => {
        if (_respondent != null) {
            return (
                <div className="row">
                    <div className="col-md-6">
                        <div className="card bg-light">
                            <div className="card-body">
                                <h3>For the operator...</h3>
                                <FormGroup>
                                    <label>"The information recorded on this page is an honest and accurate reflection of my operating experience."</label>
                                    {context.userType === WebUserType.Respondent &&
                                        <SignoffWidget isSignedOff={_respondent.hasRespondentSignedOff} signOff={() => handleSignoff()}></SignoffWidget>
                                    }
                                    {context.userType === WebUserType.User &&
                                        <SignoffLabel isSignedOff={_respondent.hasRespondentSignedOff}></SignoffLabel>
                                    }
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card bg-light">
                            <div className="card-body">
                                <h3>For the instructor...</h3>
                                <FormGroup>
                                    <label>"I am satisfied that this candidate is eligible to undertake the training/testing route indicated."</label>
                                    {context.userType === WebUserType.Respondent &&
                                        <SignoffLabel isSignedOff={_respondent.hasInstructorSignedOff}></SignoffLabel>
                                    }
                                    {context.userType === WebUserType.User &&
                                        <SignoffWidget isSignedOff={_respondent.hasInstructorSignedOff} signOff={() => handleSignoff()}></SignoffWidget>
                                    }
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else
            return (<></>)
    }

    const renderDetailsTab = () => {
        if (tab === ProfileTab.Details) {
            return (
                <div>
                    <div className="row mt-3 mb-2">
                        <div className="col-md-2">
                        </div>
                        <div className="col-md-4">
                            {renderRespondent()}
                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
                </div>
            )
        } else
            return (<></>)
    }

    const setHealthAndSafetyPassed = (value: boolean) => {
        setRespondentProperty("healthAndSafetyPassed", value);
        touchRespondent();
    }

    const renderProfessionalDiscussionTab = () => {
        if (_respondent != null && tab === ProfileTab.ProfessionalDiscussion) {
            return (
                <div>
                    <div>
                        <div className="row mb-5 mt-3">
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-4">
                            <div className="mb-5">
                                <h2>Machine Type & Training</h2>
                                <FormGroup>
                                    <label>Machine type</label>
                                    {context.userType === WebUserType.Respondent &&
                                        <TextAreaLabel value={_respondent.machineType}></TextAreaLabel>
                                    }
                                    {context.userType === WebUserType.User &&
                                        <textarea className="form-control" value={_respondent.machineType} onChange={(e) => setRespondentProperty("machineType", e.target.value)}
                                            onBlur={() => touchRespondent()}></textarea>
                                    }
                                </FormGroup>
                                <FormGroup>
                                    <label>Details of previous training</label>
                                    {context.userType === WebUserType.Respondent &&
                                        <TextAreaLabel value={_respondent.detailsOfPreviousTraining}></TextAreaLabel>
                                    }
                                    {context.userType === WebUserType.User &&
                                        <textarea className="form-control" value={_respondent.detailsOfPreviousTraining} onChange={(e) => setRespondentProperty("detailsOfPreviousTraining", e.target.value)}
                                            onBlur={() => touchRespondent()}></textarea>
                                    }
                                </FormGroup>
                            </div>
                            <div className="mb-5">
                                <h2>Health and Safety</h2>
                                <FormGroup>
                                    <label>Health and safety passed?</label>
                                    {context.userType === WebUserType.Respondent &&
                                        <YesNoLabel value={_respondent.healthAndSafetyPassed}></YesNoLabel>
                                    }
                                    {context.userType === WebUserType.User &&
                                            <YesNoWidget value={_respondent.healthAndSafetyPassed} setValue={(value) => setHealthAndSafetyPassed(value)}></YesNoWidget>
                                    }
                                    </FormGroup>
                                    {_respondent.healthAndSafetyPassed && (
                                        <FormGroup>
                                            <label>Health and safety passed date</label>
                                            {context.userType === WebUserType.User &&
                                                <input type="text" className="form-control" value={healthAndSafetyPassedDateAsString} onChange={(e) => setRespondentProperty("healthAndSafetyPassedDate", e.target.value)}
                                                onBlur={() => touchRespondent()} />
                                            }
                                            {context.userType === WebUserType.Respondent && !(_respondent.hasHealthAndSafetyPassedDate) &&
                                                <div>-</div>
                                            }
                                            {context.userType === WebUserType.Respondent && _respondent.hasHealthAndSafetyPassedDate &&
                                                <div>{_respondent.healthAndSafetyPassedDate}</div>
                                            }
                                        </FormGroup>
                                    )}
                            </div>
                            <div className="mb-5">
                                {renderTrainingNeeds()}
                            </div>
                        </div>
                        </div>
                    </div>
                    <div>
                        <div className="row mb-5 mt-3">
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-8">
                                <h2>Employment Details</h2>
                                {renderEmploymentDetails()}
                            </div>
                            <div className="col-md-2">
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row mt-3 mb-5">
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-8">
                                {renderSignoff()}
                            </div>
                            <div className="col-md-2">
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else
            return (<></>);
    }

    function handleTabChanged(e: number): void {
        setTab(e as ProfileTab);
    }

    if (answerSet != null) {
        return (
            <div>
                {renderDetailsTab()}
                {renderProfessionalDiscussionTab()}
                <div>
                    <VcrButtons count={2} title="Profile" onIndexChanged={(e) => handleTabChanged(e)} canFinish={(async () => true)} finish={() => handleFinish()}></VcrButtons>
                </div>
            </div>
        );
    } else {
        return (<></>)
    }
}

export default EfProfile;