import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Api, { IWebSession, IWorkspace, useApi, WebUserType } from '../api/Api';
import NavigateHelper from '../api/NavigateHelper';

function WorkspaceSelector() {
    const [api, setApi] = useState<Api>();
    const [currentWorkspace, setCurrentWorkspace] = useState<IWorkspace>();
    const [workspaces, setWorkspaces] = useState<IWorkspace[]>();
    const [session, setSession] = useState<IWebSession>();

    useApi(async (api) => {
        setApi(api);

        setSession(await api.getSession());

        var workspaces = await api.getWorkspacesAsync();
        setWorkspaces(workspaces.items);
        setCurrentWorkspace(workspaces.currentWorkspace);
    });

    var navigate = useNavigate();

    const handleChange = async (e: ChangeEvent<HTMLSelectElement>) => {
        for (var ws of workspaces!) {
            if (ws.token == e.target.value) {
                await api?.setCurrentWorkspaceAsync(ws);
                new NavigateHelper(navigate).goHome();
                return;
            }
        }

        throw new Error("Failed to find workspace.");
    };

    const handleBookings = () => {
        new NavigateHelper(navigate).goBookings(); 
    }

    const renderBookingsButton = () => {
        if (session?.userType === WebUserType.User) {
            return (<div className="mt-2">
                <button className="btn btn-sm btn-secondary" onClick={handleBookings}>Bookings</button>
            </div>)
        } else
            return (<></>)
    }

    if (workspaces != null) {
        return (
            <div>
                <span>
                    <select value={currentWorkspace?.token} onChange={handleChange}>
                        {workspaces.map((item) => {
                            return (
                                <option key={item.token} value={item.token}>
                                    {item.name} ({item.referenceNumber})
                                </option>
                            );
                        })}
                    </select>
                </span>
                {renderBookingsButton()}
            </div>
        );
    } else {
        return (<></>);
    }
}

export default WorkspaceSelector;