import { useEffect, useState } from "react";
import Api, { IAnswerSet, IInspectionVehicleDetails, IPrePracticalAssessmentDetails, IShortQuestionWithNotes, IWebSession, useApi } from "../api/Api";
import QuestionContext from "../api/QuestionContext";
import AnswerSetWidget from "./AnswerSetWidget";
import EfCaption from "./EfCaption";

const EfInspection: React.FC<{
    answerSet: IAnswerSet,
    vehicleDetails: IInspectionVehicleDetails,
    prePracticalAssessment: IPrePracticalAssessmentDetails,
    finish: () => void,
    context: QuestionContext
}> = ({
    answerSet,
    vehicleDetails,
    prePracticalAssessment,
    finish,
    context
}) => { 
    const [_vehicleDetails, _setVehicleDetails] = useState<IInspectionVehicleDetails>();
    const [_prePracticalAssessment, _setPrePracticalAssessment] = useState<IPrePracticalAssessmentDetails>();
    const [api, setApi] = useState<Api>();
    const [session, setSession] = useState<IWebSession>();

    useApi(async (theApi) => {
        setApi(theApi);
        setSession(await theApi.getSession());
    });

    useEffect(() => {
        if (!(_vehicleDetails) && vehicleDetails)
            _setVehicleDetails(vehicleDetails);
        if (!(_prePracticalAssessment) && prePracticalAssessment)
            _setPrePracticalAssessment(prePracticalAssessment);
    });

    const handleChange = (el: HTMLInputElement) => {
        var newDetails = {
            ..._vehicleDetails,
            [el.name]: el.value
        };
        _setVehicleDetails(newDetails as IInspectionVehicleDetails);
    }

    const handleBlur = async (el: HTMLInputElement) => {
        await api?.setVehicleDetailsFieldForCurrentWorkspaceAsync(el.name, el.value);
    }

    const handlePrePracticalAssessmentChange = (el: HTMLElement) => {
        var newDetails = {
            ..._vehicleDetails,
            [(el as any).name]: (el as any).value
        };
        _setVehicleDetails(newDetails as IInspectionVehicleDetails);
    }

    const handlePrePracticalAssessmentBlur = async (el: HTMLElement) => {
        await api?.setPrePracticalAssessmentFieldForCurrentWorkspaceAsync((el as any).name, (el as any).value);
    }

    const renderPrePracticalAssessment = () => {
        if (answerSet && _prePracticalAssessment && session?.userType == "User") {
            return (
                <div>
                    <h5>Pre-Practical Test Assessment</h5>
                    <div className="form-group mt-2">
                        <select className="form-control" value={prePracticalAssessment.status} name="status" onChange={(e) => handlePrePracticalAssessmentChange(e.target)}
                            onBlur={(e) => handlePrePracticalAssessmentBlur(e.target)}>
                            <option value="0">(Select)</option>
                            <option value="1">Continue to Practical Test</option>
                            <option value="2">Stop and do not proceed to Practical Test</option>
                        </select>
                    </div>
                    {prePracticalAssessment!.status == "2" &&
                        (
                            <div className="form-group mt-2">
                                <label>Provide the reason for stopping at this point</label>
                                <textarea className="form-control" value={prePracticalAssessment.notes} name="notes" onChange={(e) => handlePrePracticalAssessmentChange(e.target)}
                                    onBlur={(e) => handlePrePracticalAssessmentBlur(e.target)}>
                                </textarea>
                            </div>
                        )}
                </div>
            )
        } else {
            return (<></>)
        }
    }

    if (answerSet != null && _vehicleDetails != null) {
        return (
            <div>
                <EfCaption text={"All pre-use checks to be carried out in accordance with the specific instructions published in the relevant manufacturer's operating handbook"}></EfCaption>

                <div className="row mt-3">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-4">
                        <h5>Vehicle Details</h5>
                        <div className="form-group">
                            <label>Registration</label>
                            <input type="text" name="registration" className="form-control" value={_vehicleDetails.registration} onChange={(e) => handleChange(e.target)}
                                onBlur={(e) => handleBlur(e.target)} />
                        </div>
                        <div className="form-group">
                            <label>Make</label>
                            <input type="text" name="make" className="form-control" value={_vehicleDetails.make} onChange={(e) => handleChange(e.target)}
                                onBlur={(e) => handleBlur(e.target)} />
                        </div>
                        <div className="form-group">
                            <label>Model</label>
                            <input type="text" name="model" className="form-control" value={_vehicleDetails.model} onChange={(e) => handleChange(e.target)}
                                onBlur={(e) => handleBlur(e.target)} />
                        </div>
                        <div className="form-group">
                            <label>Variations</label>
                            <input type="text" name="variations" className="form-control" value={_vehicleDetails.variations} onChange={(e) => handleChange(e.target)}
                                onBlur={(e) => handleBlur(e.target)} />
                        </div>
                        <div className="form-group">
                            <label>Attachments</label>
                            <input type="text" name="attachments" className="form-control" value={_vehicleDetails.attachments} onChange={(e) => handleChange(e.target)}
                                onBlur={(e) => handleBlur(e.target)} />
                        </div>
                        <div className="form-group">
                            <label>Other</label>
                            <input type="text" name="other" className="form-control" value={_vehicleDetails.other} onChange={(e) => handleChange(e.target)}
                                onBlur={(e) => handleBlur(e.target)} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        {renderPrePracticalAssessment()}
                    </div>
                    <div className="col-md-2">
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <h5>Inspection Details</h5>
                    </div>
                    <div className="col-md-2">
                    </div>
                </div>

                <AnswerSetWidget key="InspectionAnswerSet" answerSet={answerSet} finish={() => finish()} context={context}></AnswerSetWidget>

            </div>
        );
    } else {
        return (<></>)
    }
}

export default EfInspection;