import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Api, { IBookingItem, IBookings, ILoginUserRequest, IWebSession, useApi } from '../api/Api';
import NavigateHelper from '../api/NavigateHelper';

const BookingsPage = () => {
    const [api, setApi] = useState<Api>();
    const [session, setSession] = useState<IWebSession>();
    const [bookings, setBookings] = useState<IBookings>();

    useApi(async (api) => {
        setApi(api);

        setSession(await api.getSession());
        setBookings(await api.getBookings());

        // call this just to do a refresh... (to clear support issues...)
        await api.touchAndGetWorkspacesAsync();
    });

    var navigate = useNavigate();

    const bookInOperator = (item: IBookingItem) => {
        new NavigateHelper(navigate).goBooking(item);
    }

    if (api && bookings) {
        if (bookings.items.length > 0) {
            return (
                <div>
                    <div className="row my-3">
                        <div className="col-md-12">
                            <h1>Bookings</h1>
                        </div>
                    </div>
                    <div className="row my-3">
                        {bookings!.items.map((booking) => {
                            return (
                                <div className="col-md-4" key={booking.reservation.token}>
                                    <div className="card bg-light mb-3">
                                        <div className="card-body">
                                            <h2>{booking.reservation.course.name}</h2>
                                            <div>
                                                <b>{booking.reservation.datesAsString}</b>
                                            </div>
                                            <div className="form-group mt-2">
                                                <label>Bookings: {booking.respondents.length} / {booking.reservation.numRespondentsExpected}</label>
                                            </div>
                                            <div className="form-group mt-2">
                                                <ol>
                                                    {booking.respondents.map((respondent) => {
                                                        return (
                                                            <div key={respondent.token}>{respondent.firstName} {respondent.lastName} ({respondent.referenceNumber})</div>
                                                        )
                                                    })}
                                                </ol>
                                            </div>
                                            <div className="form-group mt-2">
                                                <button className="btn btn-sm btn-primary" onClick={() => bookInOperator(booking)}>Book in Operator</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <h1>Bookings</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div><i className="fa fa-info-circle" /> You have no bookings.</div>
                        </div>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <div></div>
        );
    }
}

export default BookingsPage;