import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Api, { IWebSession, useApi } from '../api/Api';
import NavigateHelper, { TakePhotoType } from '../api/NavigateHelper';

const camera = (function () {
    let width = 0;
    let height = 0;

    const createObjects = function () {
        const video = document.createElement('video');
        video.id = 'video';
        video.className = "takephoto-video";
        //video.width = width;
        //video.height = height;
        video.autoplay = true;

        const container = getPhotoTarget();
        container.appendChild(video);

        const canvas = document.createElement('canvas');
        canvas.id = 'canvas';
        canvas.className = "takephoto-canvas";
        //canvas.width = width;
        //canvas.height = height;
        canvas.style.display = "none";
        canvas.style.width = "100%";
        container.appendChild(canvas);

        const buttons = getButtons();
        buttons[1].style.display = "none";
    };

    function getPhotoTarget(): Element {
        return document.getElementsByClassName("phototarget")[0];
    }

    /*
    function drawCoverVideoFrame(video: HTMLVideoElement, canvas: HTMLCanvasElement): void {
        const context = canvas.getContext('2d');
        if (!context) {
            console.error("Failed to get canvas 2D context.");
            return;
        }

        console.log("canvas --> ", canvas.width, canvas.height);
        console.log("video --> ", video.videoWidth, video.videoHeight);

        const canvasAspect = canvas.width / canvas.height;
        const videoAspect = video.videoWidth / video.videoHeight;

        let sx: number, sy: number, sWidth: number, sHeight: number;

        if (canvasAspect > videoAspect) {
            // The canvas is wider relative to its height than the video
            sWidth = video.videoWidth;
            sHeight = video.videoWidth / canvasAspect;
            sx = 0;
            sy = (video.videoHeight - sHeight) / 2;
        } else {
            // The canvas is taller relative to its width than the video
            sWidth = video.videoHeight * canvasAspect;
            sHeight = video.videoHeight;
            sx = (video.videoWidth - sWidth) / 2;
            sy = 0;
        }

        // Draw the current frame of the video, scaled and cropped to cover the canvas
        context.drawImage(video, sx, sy, sWidth, sHeight, 0, 0, canvas.width, canvas.height);
    }
    */

    function getButtons(): HTMLElement[] { 
        return [
            document.getElementsByClassName("takephoto-buttons-before")[0] as HTMLElement,
            document.getElementsByClassName("takephoto-buttons-after")[0] as HTMLElement,
        ]
    }

    return {
        video: null as HTMLVideoElement | null,
        context: null as CanvasRenderingContext2D | null,
        canvas: null as HTMLCanvasElement | null,
        _hasPhoto: false as boolean,

        startCamera: function (w = 680, h = 480): void {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                width = w;
                height = h;

                createObjects();

                this.video = document.getElementById('video') as HTMLVideoElement;
                this.canvas = document.getElementById('canvas') as HTMLCanvasElement;
                this.context = this.canvas.getContext('2d');

                if (this.video) {
                    navigator.mediaDevices
                        .getUserMedia({ video: true })
                        .then((stream) => {
                            this.video!.srcObject = stream as MediaStream;
                            this.video!.play();
                        })
                        .catch((error) => {
                            console.error("Error accessing the camera: ", error);
                        });
                }
            }
        },

        takeSnapshot: function (): void {
            if (this.context && this.video) {

                const target = getPhotoTarget();
                const width = target.clientWidth;

                this.canvas!.width = this.video.videoWidth;
                this.canvas!.height = this.video.videoHeight;

                // Draw the current video frame onto the canvas
                this.context.drawImage(this.video!, 0, 0, this.canvas!.width, this.canvas!.height);
                
                this.video.style.display = "none";
                this.canvas!.style.display = "inline-block";
                this._hasPhoto = true;

                const buttons = getButtons();
                buttons[0].style.display = "none";
                buttons[1].style.display = "inline-block";
            }
        },

        reset: function () {
            this.video!.style.display = "inline-block";
            this.canvas!.style.display = "none";
            this._hasPhoto = false;

            const buttons = getButtons();
            buttons[0].style.display = "inline-block";
            buttons[1].style.display = "none";
        },

        hasPhoto(): boolean {
            return this._hasPhoto;
        },

        getImage(callback: BlobCallback): void {
            this.canvas!.toBlob(callback, "image/png");
        }
    };
})();

const CameraCapture = () => {
    const [api, setApi] = useState<Api>();
    const [session, setSession] = useState<IWebSession>();
    const [header, setHeader] = useState<string>("");
    const [hasCamera, setHasCamera] = useState<boolean>(false);
    const [type, setType] = useState<TakePhotoType>();

    const location = useLocation();

    const navigate = useNavigate();

    useApi(async (api) => {
        setApi(api);

        const qs = new URLSearchParams(location.search);
        setType(qs.get("type") as TakePhotoType);

        const session = await api.getSession();
        setSession(session);

        const ws = await api.getCurrentWorkspaceAsync();

        setHeader("Taking photo for " + ws.name);

        if (!(hasCamera)) {
            setHasCamera(true)
            camera.startCamera();
        }
    });

    const takePhoto = () => {
        camera.takeSnapshot();
    };

    const retakePhoto = () => {
        camera.reset();
    }

    const cancel = () => {
        new NavigateHelper(navigate).goHome();
    }

    const uploadPhoto = () => {
        camera.getImage(async (blob) => {
            if (type! == TakePhotoType.Respondent)
                await api!.uploadPhotoForRespondentInCurrentWorkspaceAsync(blob!);
            else
                throw new Error("Cannot handle " + type!);

            new NavigateHelper(navigate).goHome();
        });
    }

    if (camera != null) {
        return (
            <div className="row mt-3">
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                    <div className="text-center">
                        <h2>{header}</h2>
                    </div>

                    <div className="phototarget"></div>

                    <div className="text-center">
                        <div className="takephoto-buttons-before">
                            <button className="btn btn-sm btn-success" onClick={() => takePhoto()}><i className="fa fa-camera"></i> Take Photo</button>
                            <button className="btn btn-sm btn-secondary ms-1" onClick={() => cancel()}>Cancel</button>
                        </div>
                        <div className="takephoto-buttons-after">
                            <button className="btn btn-sm btn-success" onClick={() => uploadPhoto()}><i className="fa fa-check"></i> Use Photo</button>
                            <button className="btn btn-sm btn-primary ms-1" onClick={() => retakePhoto()}><i className="fa fa-camera"></i> Re-take Photo</button>
                            <button className="btn btn-sm btn-secondary ms-1" onClick={() => cancel()}>Cancel</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                </div>
            </div>
        )
    } else {
        return (<></>)
    }
};

export default CameraCapture;
