import { FaCheckSquare, FaRegSquare } from "react-icons/fa"

const SignoffLabel: React.FC<{
	isSignedOff: boolean,
	signedOffText?: string,
	notSignedOffText?: string,
}> = ({
	isSignedOff,
	signedOffText,
	notSignedOffText
}) => {

	const getSignedOffText = () => {
		if (signedOffText == null || signedOffText == null)
			return "Signed off";
		else
			return signedOffText;
	}

	const getNotSignedOffText = () => {
		if (notSignedOffText == null || notSignedOffText == null)
			return "Not signed off";
		else
			return notSignedOffText;
	}

	if (isSignedOff) {
		return (
			<div className="my-2">
				<span className="signoff-label">
					<FaCheckSquare></FaCheckSquare> {getSignedOffText()}
				</span>
			</div>
		);
	} else {
		return (
			<div className="my-2">
				<span className="signoff-label">
					<FaRegSquare></FaRegSquare> {getNotSignedOffText()}
				</span>
			</div>
		)
	}
}

export default SignoffLabel;