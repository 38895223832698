import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Api, { IBookingItem, IBookings, IInductOperatorArgs, ILoginUserRequest, IReservation, IWebSession, useApi } from '../api/Api';
import NavigateHelper from '../api/NavigateHelper';

const BookingPage = () => {
    const [api, setApi] = useState<Api>();
    const [session, setSession] = useState<IWebSession>();
    const [reservation, setReservation] = useState<IReservation>();
    const [args, setArgs] = useState<IInductOperatorArgs>({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        operatorNumber: "",
    });

    const location = useLocation();

    const navigate = useNavigate();

    useApi(async (api) => {
        setApi(api);

        const qs = new URLSearchParams(location.search);
        const token = qs.get("token");

        setSession(await api.getSession());
        setReservation(await api.getReservation(token!));
    });

    const handleChange = (e: any) => {
        console.log("change...");
        const { name, value } = e.target;
        setArgs({
            ...args,
            [name]: value,
        });
    };

    const inductOperator = async () => {
        const flow = await api!.inductOperator(reservation!, args);
        if(flow.item != null)
            new NavigateHelper(navigate).goHome();          // ideally, should be set to be the operator we just inducted...
    }

    if (api && reservation) {
        return (
            <div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h1>Booking: {reservation.course.name}</h1>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Instructor</label>
                            <div>
                                <b>{session!.user.fullName}</b>
                            </div>
                        </div>
                        <div className="form-group mt-2">
                            <label>Dates</label>
                            <div>
                                <b>{reservation.datesAsString}</b>
                            </div>
                        </div>
                        <div className="form-group mt-2">
                            <label>First name</label>
                            <input type="text" className="form-control" name="firstName" value={args.firstName} onChange={handleChange}></input>
                        </div>
                        <div className="form-group mt-2">
                            <label>Last name</label>
                            <input type="text" className="form-control" name="lastName" value={args.lastName} onChange={handleChange}></input>
                        </div>
                        <div className="form-group mt-2">
                            <label>Email</label>
                            <input type="text" className="form-control" name="email" value={args.email} onChange={handleChange}></input>
                        </div>
                        <div className="form-group mt-2">
                            <label>Mobile</label>
                            <input type="text" className="form-control" name="mobile" value={args.mobile} onChange={handleChange}></input>
                        </div>
                        <div className="form-group mt-2">
                            <label>Operator number</label>
                            <input type="text" className="form-control" name="operatorNumber" value={args.operatorNumber} onChange={handleChange}></input>
                        </div>
                        <div className="form-group mt-4">
                            <button className="btn btn-sm btn-success" onClick={() => inductOperator()}>Induct Operator</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}

export default BookingPage;