import React, { useRef, useState, useSyncExternalStore } from 'react';
import logo from './logo.svg';
import './App.css';
import { UserAnswers } from './api/UserAnswers';
import Api, { IAnswerSet, IQuestion, IQuestionGroup, useSession } from './api/Api';
import { forEachChild } from 'typescript';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import NotFoundPage from './pages/NotFoundPage';
import LoginUserPage from './pages/LoginUserPage';
import LoginPage from './pages/LoginPage';
import SessionWrapper from './components/SessionWrapper';
import UserWidget from './components/UserWidget';
import LogoutPage from './pages/LogoutPage';
import BookingsPage from './pages/BookingsPage';
import BookingPage from './pages/BookingPage';
import CapturePhotoPage from './pages/CapturePhotoPage';

function App() {

    useSession((session) => {
        if (!(session.isAuthenticated) && isSecurePage())
            document.location = "/login";
    });

    const isSecurePage = () => {
        var url = document.location.pathname;
        var index = url.indexOf('?');
        if (index != -1)
            url = url.substring(0, index);
        while (url.endsWith("/"))
            url = url.substring(0, url.length - 1);
        url = url.toLocaleLowerCase();

        if (url == "/login" || url == "/login-user" || url == "/logout" || url == "/forgot-password")
            return false;
        else
            return true;
    }

    return (
        <SessionWrapper>
            <div className="chrome">
                <div className="chrome-header">
                    <img className="chrome-header-logo" src="/img/npors.png" alt="NPORS logo" />
                {/*    <UserWidget></UserWidget>*/}
                </div>
                <div className="chrome-controls">

                </div>
                <div className="chrome-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <BrowserRouter>
                                    <Routes>
                                        <Route path="/" element={<HomePage />} />
                                        <Route path="/login" element={<LoginPage />} />
                                        <Route path="/login-user" element={<LoginUserPage />} />
                                        <Route path="/logout" element={<LogoutPage />} />
                                        <Route path="/bookings" element={<BookingsPage />} />
                                        <Route path="/booking" element={<BookingPage />} />
                                        <Route path="/take-photo" element={<CapturePhotoPage />} />
                                        {/* Define more routes as needed */}
                                        <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route */}
                                    </Routes>
                                </BrowserRouter>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SessionWrapper>
        );
}

export default App;
