import { NavigateFunction } from "react-router-dom";
import { IBookingItem } from "./Api";

export enum TakePhotoType {
    Respondent = "Respondent",
    AdHoc = "AdHoc"
}

class NavigateHelper {
    private _navigate: NavigateFunction;

    constructor(navigate: NavigateFunction) {
        this._navigate = navigate;
    }

    private navigate(url: string, refresh?: boolean) {
        this._navigate(url);

        if (refresh)
            this._navigate(0);
    }

    goHome() {
        this.navigate("/", true);
    }

    goLogin() {
        this.navigate("/login");
    }

    goBookings() {
        this.navigate("/bookings");
    }

    goBooking(item: IBookingItem) {
        this.navigate("/booking?token=" + encodeURIComponent(item.reservation.token));
    }

    goTakePhoto(type: TakePhotoType) {
        this.navigate("/take-photo?type=" + encodeURIComponent(type.toString()));
    }
}

export default NavigateHelper;