import React, { Component, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Api, { ILoginUserRequest, IWebSession, useApi, WebUserType } from '../api/Api';
import NavigateHelper from '../api/NavigateHelper';

const LoginPage = () => {
    const [api, setApi] = useState<Api>();
    const [session, setSession] = useState<IWebSession>();
    const [searchParams, setSearchParams] = useSearchParams();

    useApi(async (api) => {
        setApi(api);

        setSession(await api.getSession());        

        const token = searchParams.get("impersonate");
        if (token != null && token.length > 0) {
            console.log("Setting impersonation --> " + token);
            await api.impersonateUserAsync(token);
            navigate("/");
        }
    });

    var navigate = useNavigate();

    const [args, setArgs] = useState<ILoginUserRequest>({
        email: "",
        password: "",
        rememberMe: false
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setArgs({
            ...args,
            [name]: value,
        });
    };

    const login = async () => {
        var response = await api!.loginRespondentAsync(args);
        if (!(response!.hasErrors)) 
            new NavigateHelper(navigate).goHome();
    }

    if (api) {
        return (
            <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-4">

                    <div className="text-center">
                        <h1>Operator Login</h1>
                    </div>

                    <div className="form-group mt-3">
                        <label>Operator Number/Candidate Number</label>
                        <input type="text" className="form-control" name="email" value={args.email} onChange={handleChange} />
                    </div>

                    <div className="form-group mt-3">
                        <label>Today's Access Code</label>
                        <input type="text" className="form-control" name="password" value={args.password} onChange={handleChange} />
                    </div>

                    <div className="form-group mt-3">
                        <button className="btn btn-sm btn-primary" onClick={() => login()}>Login</button>
                    </div>

                    <div className="form-group mt-3 text-center">
                        <Link to="/login-user">Instructor Login</Link>
                    </div>

                </div>
                <div className="col-md-4">
                </div>
            </div>
        );
    } else {
        return (
            <div>...</div>
        );
    }
}

export default LoginPage;